import React from 'react';

const NOTE_FREQUENCIES = {
  mainnet: {
    'C3': 130.81, 'D3': 146.83, 'E3': 164.81, 'F3': 174.61,
    'G3': 196.00, 'A3': 220.00, 'B3': 246.94
  },
  testnet: {
    'C3': 185.00, 'D3': 220.00, 'E3': 246.94, 'F3': 277.18,
    'G3': 311.13, 'A3': 369.99, 'B3': 415.30
  }
};

const NOTES = ['C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3'];
const BEAT_DURATION = 0.125; // 16th note duration
const BEATS_PER_MEASURE = 16;
const MEASURE_DURATION = BEAT_DURATION * BEATS_PER_MEASURE;
const MELODY_DURATION = MEASURE_DURATION * 4; // 4 measures

const DRUM_PATTERNS = {
  mainnet: {
    kick:  [1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    snare: [0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    hihat: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    perc:  [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 0, 1]
  },
  testnet: {
    kick:  [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    snare: [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    hihat: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0],
    perc:  [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0]
  }
};

class AudioNodeManager {
  constructor(audioContext) {
    this.audioContext = audioContext;
    this.gainNode = audioContext.createGain();
    this.analyser = audioContext.createAnalyser();
    this.beatOscillators = [];
    this.beatGainNodes = [];
    
    this.analyser.fftSize = 2048;
    this.analyser.smoothingTimeConstant = 0.3;
    
    this.gainNode.connect(this.analyser);
    this.analyser.connect(audioContext.destination);
  }

  setupBeatOscillators() {
    this.cleanupBeatOscillators();
    
    for (let i = 0; i < 4; i++) {
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      osc.started = false;
      this.beatOscillators.push(osc);
      this.beatGainNodes.push(gain);
    }
  }

  cleanupBeatOscillators() {
    this.beatOscillators.forEach(osc => {
      if (osc.started) osc.stop();
      osc.disconnect();
    });
    this.beatGainNodes.forEach(gain => gain.disconnect());
    
    this.beatOscillators = [];
    this.beatGainNodes = [];
  }

  cleanup() {
    this.cleanupBeatOscillators();
    this.gainNode.disconnect();
    this.analyser.disconnect();
  }

  getAnalyser() {
    return this.analyser;
  }

  getGainNode() {
    return this.gainNode;
  }
}

class MelodyGenerator {
  static hexToNote(hexChar) {
    const value = parseInt(hexChar, 16);
    return NOTES[value % 7];
  }

  static fromHash(puzzleHash) {
    const cleanHash = puzzleHash.replace('0x', '');
    
    const melody = [];
    let i = 0;
    while (melody.length < 64) {
      const char = cleanHash[i % cleanHash.length];
      const note = this.hexToNote(char);
      if (note) {
        melody.push({ note, char, index: i });
      }
      i++;
    }
    return melody;
  }

  static default() {
    const defaultNotes = ['C3', 'E3', 'G3', 'C3', 'E3', 'G3', 'B3', 'C3'];
    return Array(64).fill().map((_, i) => ({
      note: defaultNotes[i % defaultNotes.length],
      char: '-',
      index: i
    }));
  }
}

class BlockchainMusicManager extends React.Component {
  constructor(props) {
    super(props);
    
    this.audioContext = null;
    this.audioNodes = null;
    this.isAudioInitialized = false;
    this.melodyInterval = null;
    this.beatInterval = null;
    this.noteChangeTimeouts = [];
    this.currentMelodyTimestamp = null;
  }

  componentDidUpdate(prevProps) {
    const { isPlaying, currentBlock, isTestnet } = this.props;
    
    // Only handle audio context changes when play state changes
    if (prevProps.isPlaying !== isPlaying) {
      if (isPlaying) {
        console.log('Starting music');
        if (!this.isAudioInitialized) {
          this.initializeAudio();
        } else if (this.audioContext.state === 'suspended') {
          this.audioContext.resume();
        }
        if (currentBlock) {
          this.playMusic(currentBlock);
        }
      } else {
        console.log('Stopping music');
        this.cleanupAudio();
      }
    } 
    // Handle block changes or network changes without reinitializing audio
    else if (isPlaying && 
      (prevProps.currentBlock !== currentBlock || prevProps.isTestnet !== isTestnet) && 
      currentBlock) {
      console.log('Switching to new block or network');
      this.playMusic(currentBlock);
    }
  }

  componentWillUnmount() {
    this.cleanupAudio();
  }

  cleanupAudio = () => {
    if (this.audioContext) {
      this.audioNodes?.cleanup();
      
      if (this.melodyInterval) {
        clearInterval(this.melodyInterval);
        this.melodyInterval = null;
      }
      if (this.beatInterval) {
        clearInterval(this.beatInterval);
        this.beatInterval = null;
      }

      this.noteChangeTimeouts.forEach(timeout => clearTimeout(timeout));
      this.noteChangeTimeouts = [];

      if (this.props.onNoteChange) {
        this.props.onNoteChange({ note: null, char: null, index: null });
      }

      if (this.audioContext.state !== 'closed') {
        this.audioContext.close().then(() => {
          console.log('Audio context closed');
        }).catch(error => {
          console.error('Error closing AudioContext:', error);
        });
      }

      this.audioContext = null;
      this.audioNodes = null;
      this.isAudioInitialized = false;
    }
  };

  initializeAudio = () => {
    if (!this.isAudioInitialized) {
      console.log('Initializing audio context');
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioNodes = new AudioNodeManager(this.audioContext);
      this.audioNodes.setupBeatOscillators();
      this.isAudioInitialized = true;
    }
  };

  sendAudioData = () => {
    if (this.audioNodes && this.props.onAudioData && this.props.isPlaying) {
      const analyser = this.audioNodes.getAnalyser();
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(dataArray);
      this.props.onAudioData(dataArray);

      // Only continue animation if still playing
      if (this.props.isPlaying) {
        requestAnimationFrame(this.sendAudioData);
      }
    }
  };

  stopCurrentMelody = () => {
    // Cancel current melody by updating timestamp
    this.currentMelodyTimestamp = null;
    
    // Clear all scheduled timeouts
    this.noteChangeTimeouts.forEach(timeout => clearTimeout(timeout));
    this.noteChangeTimeouts = [];

    // Clear melody and beat intervals
    if (this.melodyInterval) {
      clearInterval(this.melodyInterval);
      this.melodyInterval = null;
    }
    if (this.beatInterval) {
      clearInterval(this.beatInterval);
      this.beatInterval = null;
    }

    // Reset note information
    if (this.props.onNoteChange) {
      this.props.onNoteChange({ note: null, char: null, index: null });
    }
  };

  playMusic = (block) => {
    try {
      // Stop any currently playing melody
      this.stopCurrentMelody();

      if (block.header_hash) {
        console.log('Playing melody with header_hash:', block.header_hash);
        const melody = MelodyGenerator.fromHash(block.header_hash);
        this.playMelodyAndBeat(melody);
      } else {
        console.warn('header_hash is not available in the currentBlock');
        const defaultMelody = MelodyGenerator.default();
        this.playMelodyAndBeat(defaultMelody);
      }
    } catch (error) {
      console.error('Error playing music:', error);
    }
  };

  playMelodyAndBeat = (melody) => {
    if (!this.audioContext) {
      console.warn('Audio context not available, reinitializing...');
      this.initializeAudio();
      if (!this.audioContext) {
        console.error('Failed to initialize audio context');
        return;
      }
    }

    const now = this.audioContext.currentTime;
    const startTime = now + 0.1;
    let nextScheduleTime = startTime;
    
    // Create a new timestamp for this melody
    const melodyTimestamp = Date.now();
    this.currentMelodyTimestamp = melodyTimestamp;

    // Start sending audio data when melody starts
    if (this.props.isPlaying) {
      requestAnimationFrame(this.sendAudioData);
    }

    const playOnce = (time) => {
      // Stop if this is no longer the current melody
      if (this.currentMelodyTimestamp !== melodyTimestamp) {
        console.log('Stopping old melody playback');
        return;
      }

      if (!this.audioContext) {
        console.log('Audio context no longer available, stopping playback');
        return;
      }

      if (this.audioContext.currentTime + 0.1 >= nextScheduleTime) {
        for (let measure = 0; measure < 4; measure++) {
          for (let beat = 0; beat < BEATS_PER_MEASURE; beat++) {
            const noteTime = nextScheduleTime + measure * MEASURE_DURATION + beat * BEAT_DURATION;
            const { note, char, index } = melody[(measure * BEATS_PER_MEASURE + beat) % melody.length];
            
            // Only schedule if this is still the current melody
            if (this.currentMelodyTimestamp === melodyTimestamp) {
              this.playPianoNote(note, noteTime, BEAT_DURATION);
              
              // Use the appropriate drum pattern based on network
              const pattern = DRUM_PATTERNS[this.props.isTestnet ? 'testnet' : 'mainnet'];
              if (pattern.kick[beat]) this.playKick(noteTime);
              if (pattern.snare[beat]) this.playSnare(noteTime);
              if (pattern.hihat[beat]) this.playHiHat(noteTime);
              if (pattern.perc[beat]) this.playPerc(noteTime);
              
              if (this.audioContext) {
                const timeout = setTimeout(
                  () => {
                    // Only update notes if this is still the current melody
                    if (this.currentMelodyTimestamp === melodyTimestamp) {
                      this.props.onNoteChange({ note, char, index });
                    }
                  }, 
                  (noteTime - this.audioContext.currentTime) * 1000
                );
                this.noteChangeTimeouts.push(timeout);
              }
            }
          }
        }
        nextScheduleTime += MELODY_DURATION;
      }

      if (this.audioContext && this.currentMelodyTimestamp === melodyTimestamp) {
        requestAnimationFrame(() => playOnce(nextScheduleTime));
      }
    };

    playOnce(startTime);
  };

  playPianoNote = (note, time, duration) => {
    const frequency = this.props.isTestnet ? 
      NOTE_FREQUENCIES.testnet[note] : 
      NOTE_FREQUENCIES.mainnet[note];
    
    if (!isFinite(frequency)) {
      console.warn(`Invalid frequency for note: ${note}`);
      return;
    }

    if (this.props.isTestnet) {
      // Create two oscillators for richer synth sound
      const osc1 = this.audioContext.createOscillator();
      const osc2 = this.audioContext.createOscillator();
      const envelope = this.audioContext.createGain();
      
      // First oscillator - main tone
      osc1.type = 'sawtooth';
      osc1.frequency.setValueAtTime(frequency, time);
      
      // Second oscillator slightly detuned for thickness
      osc2.type = 'sawtooth';
      osc2.frequency.setValueAtTime(frequency * 1.01, time);
      
      // Billie Jean synth envelope
      envelope.gain.setValueAtTime(0, time);
      envelope.gain.linearRampToValueAtTime(0.15, time + 0.05);  // Reduced from 0.3
      envelope.gain.linearRampToValueAtTime(0.1, time + 0.1);    // Reduced from 0.2
      envelope.gain.setValueAtTime(0.1, time + duration * 0.8);  // Reduced from 0.2
      envelope.gain.linearRampToValueAtTime(0, time + duration);

      osc1.connect(envelope);
      osc2.connect(envelope);
      envelope.connect(this.audioNodes.getGainNode());

      osc1.start(time);
      osc2.start(time);
      osc1.stop(time + duration);
      osc2.stop(time + duration);
    } else {
      // Original mainnet sound
      const oscillator = this.audioContext.createOscillator();
      const envelope = this.audioContext.createGain();
      
      oscillator.type = 'triangle';
      oscillator.frequency.setValueAtTime(frequency, time);
      envelope.gain.setValueAtTime(0, time);
      envelope.gain.linearRampToValueAtTime(1, time + 0.01);
      envelope.gain.linearRampToValueAtTime(0.7, time + 0.1);
      envelope.gain.exponentialRampToValueAtTime(0.00001, time + duration);

      oscillator.connect(envelope);
      envelope.connect(this.audioNodes.getGainNode());

      oscillator.start(time);
      oscillator.stop(time + duration);
    }
  };

  playKick = (time) => {
    if (this.props.isTestnet) {
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      
      osc.type = 'sine';
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      // Smoother kick with longer decay
      osc.frequency.setValueAtTime(80, time);
      osc.frequency.exponentialRampToValueAtTime(30, time + 0.2);
      
      gain.gain.setValueAtTime(1.2, time);      // Increased from 0.9
      gain.gain.exponentialRampToValueAtTime(0.001, time + 0.5); // Longer decay
      
      osc.start(time);
      osc.stop(time + 0.5);                     // Extended duration
    } else {
      // Original kick sound
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      osc.frequency.setValueAtTime(120, time);
      osc.frequency.exponentialRampToValueAtTime(30, time + 0.1);
      gain.gain.setValueAtTime(0, time);
      gain.gain.linearRampToValueAtTime(0.8, time + 0.01);
      gain.gain.exponentialRampToValueAtTime(0.01, time + 0.3);

      osc.start(time);
      osc.stop(time + 0.3);
    }
  };

  playSnare = (time) => {
    if (this.props.isTestnet) {
      // Stronger Billie Jean snare
      const osc = this.audioContext.createOscillator();
      const noise = this.audioContext.createBufferSource();
      const oscGain = this.audioContext.createGain();
      const noiseGain = this.audioContext.createGain();
      
      // Stronger tonal component
      osc.type = 'triangle';
      osc.frequency.setValueAtTime(200, time); // Higher frequency
      osc.connect(oscGain);
      oscGain.connect(this.audioContext.destination);
      
      oscGain.gain.setValueAtTime(1.0, time);   // Increased from 0.8
      oscGain.gain.exponentialRampToValueAtTime(0.001, time + 0.2);
      
      // Stronger noise component
      const bufferSize = this.audioContext.sampleRate * 0.1;
      const buffer = this.audioContext.createBuffer(1, bufferSize, this.audioContext.sampleRate);
      const data = buffer.getChannelData(0);
      
      for (let i = 0; i < bufferSize; i++) {
        data[i] = Math.random() * 2 - 1;
      }
      
      noise.buffer = buffer;
      noise.connect(noiseGain);
      noiseGain.connect(this.audioContext.destination);
      
      noiseGain.gain.setValueAtTime(0.7, time); // Increased from 0.5
      noiseGain.gain.exponentialRampToValueAtTime(0.001, time + 0.25);
      
      osc.start(time);
      osc.stop(time + 0.15);
      noise.start(time);
    } else {
      // Original snare sound
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      osc.frequency.setValueAtTime(250, time);
      osc.frequency.exponentialRampToValueAtTime(350, time + 0.05);
      gain.gain.setValueAtTime(0, time);
      gain.gain.linearRampToValueAtTime(0.7, time + 0.01);
      gain.gain.exponentialRampToValueAtTime(0.01, time + 0.2);

      const noiseBuffer = this.audioContext.createBuffer(
        1, 
        this.audioContext.sampleRate * 0.1, 
        this.audioContext.sampleRate
      );
      const noiseData = noiseBuffer.getChannelData(0);
      for (let i = 0; i < noiseData.length; i++) {
        noiseData[i] = Math.random() * 2 - 1;
      }

      const noiseSource = this.audioContext.createBufferSource();
      noiseSource.buffer = noiseBuffer;
      const noiseGain = this.audioContext.createGain();
      noiseSource.connect(noiseGain);
      noiseGain.connect(this.audioContext.destination);
      noiseGain.gain.setValueAtTime(0, time);
      noiseGain.gain.linearRampToValueAtTime(0.4, time + 0.01);
      noiseGain.gain.exponentialRampToValueAtTime(0.01, time + 0.1);
      
      osc.start(time);
      osc.stop(time + 0.2);
      noiseSource.start(time);
    }
  };

  playHiHat = (time) => {
    if (this.props.isTestnet) {
      // Stronger Billie Jean hi-hat
      const bufferSize = this.audioContext.sampleRate * 0.05;
      const buffer = this.audioContext.createBuffer(1, bufferSize, this.audioContext.sampleRate);
      const data = buffer.getChannelData(0);
      
      for (let i = 0; i < bufferSize; i++) {
        data[i] = Math.random() * 2 - 1;
        // Sharper decay curve
        data[i] *= Math.pow(1 - (i / bufferSize), 0.8);
      }
      
      const noise = this.audioContext.createBufferSource();
      const gain = this.audioContext.createGain();
      
      noise.buffer = buffer;
      noise.connect(gain);
      gain.connect(this.audioContext.destination);
      
      gain.gain.setValueAtTime(0.35, time);     // Increased from 0.25
      gain.gain.exponentialRampToValueAtTime(0.001, time + 0.1);
      
      noise.start(time);
    } else {
      // Original hi-hat code
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      osc.frequency.setValueAtTime(10000, time);
      osc.type = 'sine';
      gain.gain.setValueAtTime(0, time);
      gain.gain.linearRampToValueAtTime(0.3, time + 0.01);
      gain.gain.exponentialRampToValueAtTime(0.01, time + 0.05);

      const noiseBuffer = this.audioContext.createBuffer(
        1, 
        this.audioContext.sampleRate * 0.1, 
        this.audioContext.sampleRate
      );
      const noiseData = noiseBuffer.getChannelData(0);
      for (let i = 0; i < noiseData.length; i++) {
        noiseData[i] = Math.random() * 2 - 1;
      }

      const noiseSource = this.audioContext.createBufferSource();
      noiseSource.buffer = noiseBuffer;
      const noiseGain = this.audioContext.createGain();
      noiseSource.connect(noiseGain);
      noiseGain.connect(this.audioContext.destination);
      noiseGain.gain.setValueAtTime(0, time);
      noiseGain.gain.linearRampToValueAtTime(0.1, time + 0.01);
      noiseGain.gain.exponentialRampToValueAtTime(0.01, time + 0.05);
      
      osc.start(time);
      osc.stop(time + 0.05);
      noiseSource.start(time);
    }
  };

  playPerc = (time) => {
    if (this.props.isTestnet) {
      // Create a more electronic percussion sound using multiple oscillators
      const osc1 = this.audioContext.createOscillator();
      const osc2 = this.audioContext.createOscillator();
      const gain1 = this.audioContext.createGain();
      const gain2 = this.audioContext.createGain();
      
      osc1.type = 'triangle';
      osc1.connect(gain1);
      gain1.connect(this.audioContext.destination);
      
      osc2.type = 'square';
      osc2.connect(gain2);
      gain2.connect(this.audioContext.destination);
      
      // High "ping" sound
      osc1.frequency.setValueAtTime(700, time);
      osc1.frequency.exponentialRampToValueAtTime(900, time + 0.05);
      gain1.gain.setValueAtTime(0, time);
      gain1.gain.linearRampToValueAtTime(0.4, time + 0.001);
      gain1.gain.exponentialRampToValueAtTime(0.001, time + 0.1);
      
      // Mid "click" sound
      osc2.frequency.setValueAtTime(600, time);
      osc2.frequency.exponentialRampToValueAtTime(1200, time + 0.08);
      gain2.gain.setValueAtTime(0, time);
      gain2.gain.linearRampToValueAtTime(0.3, time + 0.003);
      gain2.gain.exponentialRampToValueAtTime(0.001, time + 0.12);
      
      osc1.start(time);
      osc2.start(time);
      osc1.stop(time + 0.16);
      osc2.stop(time + 0.12);
    } else {
      // Original perc sound
      const osc = this.audioContext.createOscillator();
      const gain = this.audioContext.createGain();
      
      osc.connect(gain);
      gain.connect(this.audioContext.destination);
      
      osc.frequency.setValueAtTime(600, time);
      osc.frequency.exponentialRampToValueAtTime(300, time + 0.1);
      gain.gain.setValueAtTime(0, time);
      gain.gain.linearRampToValueAtTime(0.5, time + 0.01);
      gain.gain.exponentialRampToValueAtTime(0.01, time + 0.1);
      
      osc.start(time);
      osc.stop(time + 0.1);
    }
  };

  render() {
    return null;
  }
}

export default BlockchainMusicManager; 